export const utils = {
	formatNumber(number) {
		if(!isNaN(number) && number > 0){
			return new Intl.NumberFormat().format(number);
		}else{
			return '-'
		}
		// const formatted = new Intl.NumberFormat().format(number);

		// console.log(number, formatted)
		
		// if (isNaN(formatted)) {
		// 	return '-';
		// }

		// return formatted;
	}
};