
import { defineStore } from 'pinia'

export const useDD = defineStore('DealDesk', {
	state: () => ({
		percents: false,
		audienceCount: null,
		filters: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: []
		},
		selected: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: ['C-Level', 'Directors', 'Managers', 'Vice Presidents']
		},
		acr: null,
		firstCheckID: null,
		DD: [],
		reportSetup: null,
		draftID: null,
		reloadDraft: false,
		type: null,
		headers: {
			company: {
				show: false,
				value: {}
			},
			sales_person: {
				show: false,
				value: {}
			},
			start_end_date: {
				show: false,
				value: {}
			},
			ticket: {
				show: false,
				value: {}
			},
			client:{
				show: false,
				value: {}
			},
			selected_logo:{
				show: false,
				value: {}
			}
		},
		pageTabs:[
			{
				value: 'quote',
				checked: true,
				text: 'The Quote',
				items:[{
					text: 'Quote  (Leads)',
				},{
					checked: true,
					value: 'total_leads',
					text: 'Total Leads',
				},{
					checked: true,
					value: 'cost_per_lead',
					text: 'Cost per Lead',
				},{
					checked: true,
					value: 'cost_of_program',
					text: 'Cost of Program',
				},{
					text: 'Quote (Impressions)',
				},{
					checked: false,
					value: 'total_impressions',
					text: 'Total Impressions',
				},{
					checked: false,
					value: 'cost_per_mille',
					text: 'Cost per Mille',
				},{
					value: 'cost_of_program_impressions',
					text: 'Cost of Program',
				}]
			},{
				checked: true,
				value: 'recommendations',
				text: 'Recommendations',
				items: null
			},{
				checked: true,
				value: 'audience_selected',
				text: 'Audience Selected',
				items: [{
					checked: true,
					value: 'regions',
					text: 'Regions',
				},{
					checked: true,
					value: 'countries',
					text: 'Countries',
				},{
					checked: true,
					value: 'job_functions',
					text: 'Job Functions',
				},{
					checked: true,
					value: 'sub_function',
					text: 'Sub-Function',
				},{
					checked: true,
					value: 'job_level',
					text: 'Job Level',
				},{
					checked: true,
					value: 'industry_verical',
					text: 'Industry Verical',
				},{
					checked: true,
					value: 'company_revenue',
					text: 'Company Revenue',
				},{
					checked: true,
					value: 'company_size',
					text: 'Company Size',
				}]
			},{
				checked: true,
				value: 'audience_breakdown',
				text: 'Audience Breakdown',
				items: [{
					checked: true,
					value: 'across_regions',
					text: 'Across Regions',
				},{
					checked: true,
					value: 'across_industries',
					text: 'Across Industries',
				},{
					checked: true,
					value: 'across_job_level',
					text: 'Across Job Levels',
				},{
					checked: true,
					value: 'across_job_functions',
					text: 'Across Job Functions',
				},{
					checked: true,
					value: 'across_revenue',
					text: 'Across Revenue',
				},{
					checked: true,
					value: 'across_company_size',
					text: 'Across Company Size',
				}]
			// },{
			// 	value: 'geo_breakdown',
			// 	text: 'Geo Breakdown'
			},{
				checked: true,
				value: 'value_adds',
				text: 'Value Adds',
				items: [{
					checked: true,
					value: 'lvInclude',
					text: "Let's Verify Included",
				},{
					checked: true,
					value: 'demandInclude',
					text: 'INFUSE Demand Accelerator Included'
				},{
					checked: true,
					value: 'academyInclude',
					text: 'INFUSE Academy Included'
				}]
			},{
				checked: false,
				value: 'text_block',
				text: 'Insert Text Block',
				content: {
					title: '',
					content: '',
					word: ''
				}
			}
		],
		table_columns:[
			{
				id: 1,
				checked: true,
				value: 'Total Leads'
			},{
				id: 2,
				checked: false,
				value: 'Total Impressions'
			},{
				id: 3,
				checked: true,
				value: 'Cost per Lead'
			},{
				id: 4,
				checked: false,
				value: 'Cost per Mille'
			},{
				id: 5,
				checked: true,
				value: 'Cost of Program'
			}
		],
		table_columns_order: [],
		audienceBlocks: {
			across_regions: true,
			across_industries: true,
			across_job_level: true,
			across_job_functions: true,
			across_revenue: true,
			across_company_size: true,
		},
		blocksInclude: {
			lvInclude: true,
			demandInclude: true,
			academyInclude: true
		},
		exportPDF: false,
		trueLeads: 0,
		cart: [],
		currency: '$'
	}),
	actions: {
		resetDD(){
			this.$reset();
		},
		addPercents (data) {
			this.percents = data
		},
		setAudience(data){
			this.audienceCount = data
		},
		setFilters(key, data){
			this.filters[key] = data;
		},
		addSelected(data){
			this.selected = data;
		},
		updateACR(data){
			this.acr = data;
		},
		setDD(data){
			this.DD = data;
		},
		setReportSetup(data){
			this.reportSetup = data;
		},
		setFromData(data){
			const keys = Object.keys(this.$state);
			keys.forEach(key => {
				if (key in data.jsonData) {
					this[key] = data.jsonData[key]
				}
			})
		},
		setDraftID(data){
			this.draftID = data
		},
		updateReloadState(state){
			this.reloadDraft = state;
		},
		setType(type){
			this.type = type;
		},
		exportReport(state){
			this.exportPDF = state;
		},
		setRecommendations(data){
			let index = this.pageTabs.findIndex(item => item.value === 'recommendations');
			this.pageTabs[index].items = data;
			// this.recommendations = data;
		},
		getDraftForSave(){
			return {
				percents: this.percents,
				audienceCount: this.audienceCount,
				filters: this.filters,
				selected: this.selected,
				acr: this.acr,
				firstCheckID: this.firstCheckID,
				DD: this.DD,
				reportSetup: this.reportSetup,
				headers: this.headers,
				pageTabs: this.pageTabs,
				cart: this.cart
			}
		},
		checkVisability(key, child){
			var obj =  this.pageTabs.find(item => item.value === key);
			if(child && obj.items){
				obj = obj.items.find(item => item.value === child);
			}
			return obj ? obj.checked : true;
		},
		getCustomText(){
			let obj = this.pageTabs.find(item => item.value === 'text_block');
			return obj.content;
		},
		addToCart(data){
			this.cart.push(data)
		},
		removeToCart(index){
			this.cart.splice(index, 1)
		},
		setCurrency(data) {
			this.currency = data
		}
	}
})
