// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'

function checkRole() {
	const authStore = useAuthStore()
	if (authStore.role != 'Deal Desk Expert') {
		router.push({ name: 'HourGlass' })
	}
}

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Login/'),
		meta: {
			title: 'Live INFUSE Login',
			requiresGuest: true
		}
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/ForgotPassword/'),
		meta: {
			title: 'Live INFUSE Restore Password',
			requiresGuest: true
		}
	},
	{
		path: '/order-review',
		name: 'OrderReview',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderReview/'),
		meta: {
			title: 'Live INFUSE Order Review',
		}
	},
	{
		path: '/',
		name: 'HourGlass',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/HourGlass/'),
		meta: {
			title: 'Live INFUSE Dashboard'
		}
	},
	{
		path: '/mobile',
		name: 'Mobile',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Mobile/'),
		meta: {
			title: 'Live INFUSE Dashboard Mobile'
		}
	},
	{
		path: '/deal-desk',
		name: 'Audience',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/'),
		meta: {
			title: 'Live INFUSE Dashboard Audience'
		},
		children: [
			{
				path: 'audience',
				name: 'Audience',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/AudienceSelection'),
				meta: {
					title: 'Live INFUSE Dashboard Audience Selection'
				},
				beforeEnter: [checkRole],
			},
			{
				path: 'programs-setup',
				name: 'ProgramsSetup',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/ProgramsSetup'),
				meta: {
					title: 'Live INFUSE Dashboard Programs Setup'
				},
				beforeEnter: [checkRole],
			},
			{
				path: 'report',
				name: 'Report',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/screens/Report'),
				meta: {
					title: 'Live INFUSE Dashboard Report'
				},
			},
			{
				path: 'cart',
				name: 'OrderReview',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/OrderReview/'),
				meta: {
					title: 'Live INFUSE Dashboard Order Review'
				},
			},
		]
	},
	{
		path: '/',
		name: 'CQBuilder',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/'),
		meta: {
			title: 'Live INFUSE Client Quote Builder'
		},
		children: [
			{
				path: '/product-setup',
				name: 'ProductSetup',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/ProductSetup'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Product Setup'
				},
				beforeEnter: [checkRole],
			},
			{
				path: '/target-flow',
				name: 'TargetFlow',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/TargetFlow'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Target Flow'
				},
				beforeEnter: [checkRole],
			},
			{
				path: '/insertion-order',
				name: 'InsertionOrder',
				component: () => import(/* webpackChunkName: "main" */ '@/pages/CQBuilder/screens/InsertionOrder'),
				meta: {
					title: 'Live INFUSE Client Quote Builder Insertion Order'
				},
				beforeEnter: [checkRole],
			},
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 }
	}
})

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore()
	const token = authStore.access_token
	const title = to.meta.title

	if (title) {
		document.title = title
	}

	if (to.meta.requiresGuest && authStore.isAuth) {
		next({ name: 'HourGlass' })
	} else if (!token && !to.meta.requiresGuest) {
		next({ name: 'Login' })
	} else {
		next()
	}
})

export default router